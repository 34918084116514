<template>
  <div>

    <AudienceEditorModal 
      ref="audience-editor-modal"
      :item="selected_item"
      @updated="updated_audience"
      @created="created_audience"
    />
    
    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2"
        @click="create_audience_clicked"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('ADVANCED_SEARCH.CREATE_AUDIENCE') }}</a
      >

    </div>
    
    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="sales-table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >

      <template #cell(actions)="{ item }">
        <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="select_item_clicked(item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Write.svg" />
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_item_clicked(item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/assets/svg/Trash.svg" />
          </span>
        </a>
      </template>
    </b-table>

  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


import axios from 'axios';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import AudienceEditorModal from './AudienceEditorModal.vue';


export default {
  name: 'AudienceTable',
  components: {
    Multiselect,
    AudienceEditorModal,
  },
  mixins: [ toasts ],
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings', 'currentUser', 'isTHS']),
    
  },
  props: [],
  emits: [],
  async mounted() {

    await this.load_audiences();
  },
  data() {
    return {
      selected_item: {},
      items: [],
      
      headers: [
        {
          label: this.$t('ADVANCED_SEARCH.AUDIENCE_NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        }
      ],
      total_rows: 0,
      current_page: 1,
      per_page: 100,
      search: null,
      user_options: []
    };
  },
  watch: {
    
  },
  methods: {

    created_audience(item) {
      this.items.unshift(item);
    },

    updated_audience(item) {
      const audience_index = this.items.findIndex(i => i.id === item.id);
      
      this.items[audience_index] = item;
      this.items = [...this.items ]; 
    },

    create_audience_clicked() {
      this.selected_item = {};
      this.$refs['audience-editor-modal'].show();
    },
    
    async load_audiences() {
      
      const res = await axios.get(`/user/audiences`);

      if (res.status === 200) {
        this.items = res.data;
      }
    },

    select_item_clicked(item) {
      this.selected_item = item;
      this.$refs['audience-editor-modal'].show();
    },

    async delete_item_clicked(id) {
      const res = await axios.delete(`/user/audience/${id}`);

      if (res.status === 204) {
        this.items = this.items.filter(item => item.id !== id);
        console.log('ok');
      }
    },
    
  }
};
</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
  
</style>

